import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpParamsService } from '../service/http-params.service';
import { ApiConstant } from '@api-module/api.endpoint.constant';
import { IResponse } from '@api-module/model/common/i-response';
import { Injectable } from '@angular/core';
import { PhoneNumber } from '@api-module/model/account/i-phone-number.model';
import {UploadFile} from "ng-zorro-antd";
import { BcanCreateModel } from '@api-module/model/account/i-bcan-create.model';
import { IAddressProofModel } from '@api-module/model/account/i-address-proof-model';
import {RpqDisplayModel} from "../../account-setting/risk-profile-update/model/rpq-display.model";

@Injectable({
  providedIn: 'root'
})

export class AccountUpdateRestService {
	readonly PROTECTED_BASE_URL: string = ApiConstant.PROTECTED + ApiConstant.ACCOUNT_UPDATE;
	constructor(private http: HttpClient, private paramService: HttpParamsService) {
		
	}
	
	getProductStatus(){
		return this.http.get(this.PROTECTED_BASE_URL+"/get-activated-products",{});
	}
	
	getAccountList() {
		return this.http.get<IResponse<any>>(this.PROTECTED_BASE_URL+"/get-account-list",{});
	}
	
	updateAddress(addressJson: string){
		return this.http.post(this.PROTECTED_BASE_URL+"/update-address",addressJson);
	}
	
	updateBeneAddressInfo(addressJson: string){
		return this.http.post(this.PROTECTED_BASE_URL+"/update-bene-address-info",addressJson);
	}
	
	updatePassword(uid:string , passwordJson: any){
		const params = this.paramService.buildHttpParams({uid});
		return this.http.post(this.PROTECTED_BASE_URL+"/update-password",passwordJson,{params});
	}
	
	updateFsmonePassword(uid: string , passwordJson: any) {
		const params = this.paramService.buildHttpParams({uid});
		return this.http.post(this.PROTECTED_BASE_URL + '/update-fsmone-password', passwordJson, {params});
	}
	
	get2faStatus(uid:string,isMobile: Boolean){
		const params = this.paramService.buildHttpParams({uid,isMobile});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+"/get-two-factor-auth-status",{},{params});
	}

	get2faRegistrationStatus() {
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + '/get-two-fa-registration-status', {});
	}
	
	getTrustedDevices(){
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+"/get-registered-trusted-devices",{});
	}

	getSmsOtpMobileNo(){
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+"/get-sms-otp-mobile-number",{});
	}

	getEmailOtpEmail(){
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+"/get-email-otp-email",{});
	}

	getTaxUpdateInfo(refno: string) {
		const params = this.paramService.buildHttpParams({refno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+"/get-tax-update-info",{},{params});
	}
	
	updateTaxInfo(data: any){
		return this.http.post<IResponse<string>>(this.PROTECTED_BASE_URL+"/update-tax-info",data);
	}
	
	removeTaxInfo(data: any){
		return this.http.post<IResponse<string>>(this.PROTECTED_BASE_URL+"/remove-tax-info",data);
	}
	
	updatePersonalInfo(uid: string, data: any) {
//		const params = this.paramService.buildHttpParams({uid});
//		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+"/update-personal-info",data,{params});
	}
	
	updateSalutation(paramRefno:string, allAccount:boolean, paramSalutation:string){
		const params = this.paramService.buildHttpParams({paramRefno, allAccount, paramSalutation});
		return this.http.post<IResponse<string>>(this.PROTECTED_BASE_URL+"/update-salutation",{},{params});
	}
	
	updateBeneSalutation(paramRefno:string, paramSalutation:string){
		const params = this.paramService.buildHttpParams({paramRefno, paramSalutation});
		return this.http.post<IResponse<string>>(this.PROTECTED_BASE_URL+"/update-bene-salutation",{},{params});
	}
	
	splitMobilePhoneNum(data:any) {
		return this.http.post<IResponse<PhoneNumber>>(this.PROTECTED_BASE_URL+"/split-mobile-phone-num", data);
	}
	
	updateContactInfo(uid:string , data: string) {
		const params = this.paramService.buildHttpParams({uid});
		return this.http.post<IResponse<String>>(this.PROTECTED_BASE_URL+"/update-contact-info",data,{params});
	}
	
	updateBeneContactInfo(uid:string , data: any) {
		const params = this.paramService.buildHttpParams({uid});
		return this.http.post<IResponse<String>>(this.PROTECTED_BASE_URL+"/update-bene-contact-info",data,{params});
	}
	
	updateUsIndiciaInfo(uid:string , data: string) {
		const params = this.paramService.buildHttpParams({uid});
		return this.http.post<IResponse<String>>(this.PROTECTED_BASE_URL+"/update-us-indicia-info",data,{params});
	}
	
	updateEmploymentInfo( data: string) {
		return this.http.post<IResponse<string>>(this.PROTECTED_BASE_URL+"/update-employment-info",data);
	}
	
	updateBeneEmploymentInfo(data: string) {
		return this.http.post<IResponse<string>>(this.PROTECTED_BASE_URL+"/update-bene-employment-info",data);
	}
	
	updateLangPreferences(uid: string, data: string) {
		const params = this.paramService.buildHttpParams({uid});
		return this.http.post<IResponse<string>>(this.PROTECTED_BASE_URL+"/update-lang-preferences",data, {params});
	}
	
	updatePics(uid:string, data:string){
		const params = this.paramService.buildHttpParams({uid});
		return this.http.post<IResponse<string>>(this.PROTECTED_BASE_URL+"/update-pics",data, {params});
	}
	
	updateIdentityDisclosure(uid: string, data: string){
		const params = this.paramService.buildHttpParams({uid});
		return this.http.post<IResponse<string>>(this.PROTECTED_BASE_URL+"/update-identity-disclosure",data, {params});
	}
	
	updatePdfPassword(data: string) {
		return this.http.post<IResponse<string>>(this.PROTECTED_BASE_URL + '/update-pdf-password', data);
	}
	
	sendOtp(refno: string, mobilePhone:any) {
		const params = this.paramService.buildHttpParams({refno});
		return this.http.post<IResponse<Object>>(this.PROTECTED_BASE_URL+"/request-otp-to-phone-number",mobilePhone, {params});
	}
	
	verifySMSOtp(data: string, uid: string) {
		const params = this.paramService.buildHttpParams({uid});
		return this.http.post<IResponse<Object>>(this.PROTECTED_BASE_URL+"/verify-sms-otp",data,{params});
	}
	
	getRpqQnA(){
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+"/get-risk-profile-question-answer",{});
	}

	getVakaQna(){
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+"/get-latest-virtual-asset-question-and-answer",{});
	}

	updateVaka(json:any, refno:string){
		const params = this.paramService.buildHttpParams({refno});
		return this.http.post<IResponse<string>>(this.PROTECTED_BASE_URL+"/update-virtual-asset-knowledge",json, {params});
	}


	piDeclarationSubmit(files: UploadFile[], refnoList, piType: string, piFinancialStat: string, encryptedPw: string, e2eeRandom: string) {
		const formData = new FormData();
		if (files?.length > 0 ) {
			files.forEach((file: any) => {
				formData.append('files', file);
			});
		}
		piFinancialStat = encodeURIComponent(piFinancialStat);
		// console.log('piDeclarationSubmit req params : ' , refnoList, piType , piFinancialStat);

		const params = this.paramService.buildHttpParams({refnoList, piType, piFinancialStat, encryptedPw, e2eeRandom});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.SUBMIT_PI_DECLARATION, formData, {params});
	}
	
	createBcan(dto: any){
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.CREATE_BCAN, dto);
	}

	deactivateCnStockService(refno: string) {
		const params = this.paramService.buildHttpParams({refno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.DEACTIVATE_CN_STOCK_SERVICE, {}, {params});
	}
	
	acceptTc(refno: string){
		const params = this.paramService.buildHttpParams({refno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.ACCEPT_TC, {}, {params});
	}

	acceptAllAcctTc(){
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.ACCEPT_All_ACCT_TC, {});
	}
	
	updateSgStockConsent(obj: any){
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.UPDATE_SG_STOCK_CONSENT , obj);
	}
	
	uploadW8benAndActivate(files: UploadFile[], refno: string){
		const formData = new FormData();
		if (files?.length > 0 ) {
			files.forEach((file: any) => {
				formData.append('files', file.blobObj, file.filename);
			});
		}
		const params = this.paramService.buildHttpParams({refno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL + ApiConstant.UPLOAD_W8BEN_FORM, formData, {params});
	}
	
	submitAddressProof(dto:IAddressProofModel){
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.SUBMIT_ADDRESS_PROOF, dto)
	}
	
	updateUsLiveDataProUser(paramProFlag:string, paramPassword: string, paramE2eeRandom: string){
		const params = this.paramService.buildHttpParams({paramProFlag,paramPassword,paramE2eeRandom});
		return this.http.post<IResponse<String>>(this.PROTECTED_BASE_URL+ApiConstant.UPDATE_US_LIVE_DATA_PRO_USER, {},{params});
	}
	
	getHkidrAccountList() {
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.GET_HKIDR_ACCT_LIST,{});
	}

	getHkidrFormDetails(paramRefno:string){
		const params = this.paramService.buildHttpParams({paramRefno});
		return this.http.post<IResponse<String>>(this.PROTECTED_BASE_URL+ApiConstant.GET_HKIDR_FORM_DETAILS, {},{params});
	}

	createHkStocksIdr(dto){
		return this.http.post<IResponse<String>>(this.PROTECTED_BASE_URL+ApiConstant.CREATE_HK_STOCKS_IDR, dto);
	}

	getAllBankAccountDetails(refno: string) {
		const params = this.paramService.buildHttpParams({refno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.GET_ALL_BANK_ACCOUNT_DETAILS, {}, {params});
	}

	getBankNameList() {
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.GET_BANK_NAME_LIST, {});
	}

	getBankNameListByCurrency(currency: string) {
		const params = this.paramService.buildHttpParams({currency});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.GET_BANK_NAME_LIST_BY_CURRENCY, {}, {params});
	}

	confirmUpdateBankInfo(currencyList, localOrOverseasList, beanList, deleteLocalList, deleteOverseasList, otherLocalBankList, creditFlag, refno) {
		const params = this.paramService.buildHttpParams({currencyList, localOrOverseasList, beanList, deleteLocalList, deleteOverseasList, otherLocalBankList, creditFlag, refno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.CONFIRM_UPDATE_BANK_INFO, {}, {params});
	}

	getIgbConsent(refno: string) {
		const params = this.paramService.buildHttpParams({refno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.GET_IGB_CONSENT, {}, {params});
	}

	acceptLinkIgbDetails(refno: string) {
		const params = this.paramService.buildHttpParams({refno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.ACCEPT_LINK_IGB, {}, {params});
	}

	unlinkIgbAccount(refno: string) {
		const params = this.paramService.buildHttpParams({refno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.UNLINK_IGB_ACCOUNT, {}, {params});
	}

	isIgbEnabled() {
		return this.http.get<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.IS_IGB_ENABLED, {});
	}

	getRpqQuestionAnswer() {
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.GET_RPQ_QUESTION_ANSWER, {});
	}

	checkRpqConsecutiveUpdate(refnos: string[]) {
		const params = this.paramService.buildHttpParams({refnos});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.CHECK_RPQ_CONSECUTIVE_UPDATE, {}, {params});
	}

	validateSaveGetRpqResultMultiple(rpqDisplayModelList: RpqDisplayModel[], refno: string[], isAppetiteCheckNeeded: boolean) {
		const params = this.paramService.buildHttpParams({refno, isAppetiteCheckNeeded});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.VALIDATE_SAVE_GET_RPQ_RESULT_MULTIPLE, rpqDisplayModelList, {params});
	}

	validateRpqAge(rpqDisplayModelList: RpqDisplayModel[], refno: string[]) {
		const params = this.paramService.buildHttpParams({refno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.VALIDATE_RPQ_AGE, rpqDisplayModelList, {params});
	}

	getUserRiskProfilingData(rpqDisplayModelList: RpqDisplayModel[], assessmentOk: string, disagreeAssessResponse: string , refno: string) {
		const params = this.paramService.buildHttpParams({assessmentOk, disagreeAssessResponse, refno});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.GET_USER_RISK_PROFILING_DATA, rpqDisplayModelList, {params});
	}

	getOccupationList() {
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.GET_OCCUPATION_LIST, {});
	}

	updateOccupation(account: string) {
		const params = this.paramService.buildHttpParams({account});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.UPDATE_OCCUPATION, {}, {params});
	}

	getExpiredRpqRefnoListDpmsIncludedWithType() {
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.GET_EXPIRED_RPQ_REFNO_LIST_DPMS_INCLUDED_WITH_TYPE, {});
	}
	
	//GET_ACCOUNT_UPDATE_DETAILS
	getAccountUpdateDetails(accountNo){
		const params = this.paramService.buildHttpParams({accountNo});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.GET_ACCOUNT_UPDATE_DETAILS, {}, {params});
	}

	passwordExpiryReminded(){
		const params = new HttpParams().set('paramKeepPwd', 'Y');
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.PWD_EXP_REMINDED, {}, {params});
	}

	getStockWatchlistPreference(){
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.GET_STOCK_WATCHLIST_PREFERENCE, {});
	}
	
	updateClientStockWatchlistPreference(autoAddWatchlist: string,stockWatchlistId: string) {
		const params = this.paramService.buildHttpParams({autoAddWatchlist,stockWatchlistId});
		return this.http.post<IResponse<any>>(this.PROTECTED_BASE_URL+ApiConstant.UPDATE_STOCK_WATCHLIST_PREFERENCE, {},{params});
	}
}
